/** @jsx jsx */
import { Styled, Box, jsx } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const NewsPage = ({ data }) => (
  <Layout>
    <SEO title="Ajankohtaista" />
    <Styled.h1>Ajankohtaista</Styled.h1>
    <Box sx={{ fontFamily: "display", fontSize: [3, 4] }}>
      <Styled.p>
        Lokakuussa 2019 perustettu Santevin on samppanjoihin erikoistunut
        maahantuonnin tukkuliike. Santevin sijaitsee Tampereen ydinkeskustassa
        Champagne Bar Santen yhteydessä.
      </Styled.p>
      <Styled.p>
        Jakelukanavamme kattavat  tällä hetkellä koko Suomen ravintolat ja
        anniskeluluvan omaavat yritykset.
      </Styled.p>
    </Box>
    {/**data.body && documentToReactComponents(data.body.json)*/}
  </Layout>
)

export default NewsPage

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "ajankohtaista" }) {
      title
      slug
      body {
        json
      }
    }
  }
`
